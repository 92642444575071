<template>
    <div class="create-company-container w-100 mt-6">
        <div class="create-company-header d-flex align-items-center justify-content-between">
            <span v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'super admin'" class="fs-14 text-white">Subscribed Products</span>
            <span v-else class="fs-14 text-white">Accessible Products</span>
            <span class="pointer mr-2" @click="toggleSubscribedProducts">
                <i v-if="show_subscribed_products" class="icon-chevron-double-up text-white"
                    style="font-size: 20px"></i>
                <i v-else class="icon-chevron-double-down text-white" style="font-size: 20px;"></i>
            </span>
        </div>
        <div class="mb-4 collapse show" id="show_subscribed_products" v-if="show_subscribed_products">
            <div v-if="ajax_call_in_progress" class="d-flex align-items-center justify-content-center w-100">
                <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
            </div>
            <div class="d-flex align-items-center justify-content-center w-100 pt-8" v-else-if="!ajax_call_in_progress && products_data.subscribed_products.length <=0">
                <span>You don't have any subscribed product, Please subscribe.</span>
            </div>
            <div v-else @scroll="infiniteScrollSubscribed" style="height: 350px; overflow-y: auto;" class="row flex-wrap mr-0">
                <div v-for="(sub_prods, sub_prods_index) in products_data.subscribed_products" :key="sub_prods_index+'sub_prods_index'" class="col-lg-2 col-md-4 col-sm-12 mt-7 d-flex justify-content-center">
                    <div class="w-150pxcenter w-100 h-100">
                        <div class="access-tabs text-center d-flex flex-column justify-content-center" style="height:125px">
                            <div class="pointer" @click="goToProductPage(sub_prods.id)">
                                <div style="margin:10px auto">
                                    <img :src="sub_prods.logo_blue" width="30" />
                                </div>
                                <span class="pt-2 fw-600" style="font-size:11px;color: #00448b;">
                                    {{sub_prods.product_name}}</span>
                            </div>
                            <template v-if="(comp_data.industry_type != 'Accommodation and food services') && sub_prods.product_name == 'DigiPOS'">

                            </template>
                            <template v-else>
                                <button v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'super admin'" class="subs-btn mt-2 pointer" style="outline:none;"
                                        @click="unSubscribeProd(sub_prods.id, sub_prods.product_name)">Unsubscribe</button>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="loading_scroll_subscribed" class="mt-5 mb-5" id="spinner">
                <half-circle-spinner :animation-duration="1000" :size="50" color="#0d84f2" style="margin: 0 auto;" />
            </div>
        </div>
        <div>
            <div class="create-company-header mt-8 d-flex align-items-center justify-content-between">
                <span v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'super admin'" class="fs-14 text-white">Other Products</span>
                <span v-else class="fs-14 text-white">Other Subscribed Products</span>
                <span class="pointer mr-2" :class="[show_other_products == true ? 'arrow_up' : 'arrow_down']" @click="toggleOtherProducts()">
                    <i class="icon-chevron-double-down text-white" style="font-size: 20px;"></i>
                </span>
            </div>
            <div class="mt-4 mb-4" v-if="show_other_products">
                <div v-if="ajax_call_in_progress" class="d-flex align-items-center justify-content-center w-100">
                    <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                </div>
                <div class="d-flex align-items-center justify-content-center w-100" v-else-if="!ajax_call_in_progress && products_data.unsubscribed_products == 0">
                    <span>No products Subscribed</span>
                </div>
                <div v-else class="mt-4">
                    <div @scroll="infiniteScrollUnSubscribed" style="height: 350px; overflow-y: auto;" class="row flex-wrap mr-0">
                        <template v-for="(unsub_prods, unsub_prods_index) in products_data.unsubscribed_products">
                            <!-- <template v-if="comp_data.industry_type == 'Manufacturing' && unsub_prods.product_name == 'DigiPOS'">
                            </template> -->
                            <template>
                                <div :key="unsub_prods_index+'unsub_prods_index'" class="col-lg-2 col-md-4 col-sm-12 mt-7 d-flex justify-content-center">
                                    <div class="w-150pxcenter w-100 h-100">
                                        <div class="access-tabs text-center d-flex flex-column justify-content-center" style="height: 125px;">
                                            <div style="margin:0 auto">
                                                <img :src="unsub_prods.logo_blue" width="30" />
                                            </div>
                                            <span class="pt-2 fw-600" style="font-size:11px;color: #00448b;">{{unsub_prods.product_name || "-"}}</span>
                                            <template v-if="(comp_data.industry_type != 'Accommodation and food services') && unsub_prods.product_name == 'DigiPOS'">

                                            </template>
                                            <template v-else>
                                                <div v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'super admin' " >
                                                    <button v-if="unsub_prods.requested == false" class="subs-btn mt-2 pointer mx-auto" style="outline:none;width: fit-content;"
                                                        @click="openSubscriptionModal(unsub_prods.id, unsub_prods.product_name)">Subscribe</button>
                                                    <button v-if="unsub_prods.requested == true" class="btn-danger fs-10 p-2 mt-2 mx-auto pointer" style="outline:none;border-radius: 5px !important;width: fit-content;line-height: 1;padding: 5.5px 3px !important;;"
                                                        @click="withdrawSubscription(unsub_prods.id)">Withdraw Subscription</button>
                                                </div>
                                                <div v-else>
                                                    <button v-if="unsub_prods.requested == false" class="subs-btn mt-2 pointer mx-auto" style="outline:none;width: fit-content;"
                                                        @click="requestAccess(unsub_prods.id, unsub_prods.product_name)">Request Access</button>
                                                    <button v-if="unsub_prods.requested == true" class="btn-danger fs-10 p-2 mt-2 mx-auto pointer" style="outline:none;border-radius: 5px !important;width: fit-content;line-height: 1;padding: 5.5px 3px !important;;"
                                                        @click="withdrawRequest(unsub_prods.id)">Withdraw Request</button>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </template>
                    </div>
                    <div v-if="loading_scroll_unsubscribed" class="mt-5 mb-5" id="spinner1">
                        <half-circle-spinner :animation-duration="1000" :size="50" color="#0d84f2" style="margin: 0 auto;" />
                    </div>
                </div>
            </div>
        </div>
        <subscription-modal v-if="show_subscription_modal" @hide-subscription-modal="hideSubscriptionModal" :product_name = product_name
            modal_name="Subscription-Modal" :product_id="product_id" :subscription_text="subscription_text" :error_text="error_text" @sendSubscription="sendSubscription" ></subscription-modal>
        <withdraw-subscription v-if="show_withdraw_modal" @hide-withdraw-modal="hideWithdrawModal"
            modal_name="Withdraw-Modal" ></withdraw-subscription>
        <request-access v-if="show_request_access_modal" @hideRequestAccess="hideRequestAccess"
            modal_name="Request-Access-Modal" :product_id="product_id" :product_name="product_name" :error_text="error_text" @sendRequestAccess="sendRequestAccess" ></request-access>
    </div>
</template>

<script>
    import companies from '../mixins/companies';
    import SubscriptionModal from './SubscriptionModal.vue'
    import {HalfCircleSpinner} from 'epic-spinners';
    import globals from '../globals';
    import { EventBus } from '../eventBus/nav-bar-event';
    import WithdrawSubscription from './WithdrawSubscription.vue';
    import product from '../mixins/product';
    import RequestAccess from './RequestAccess.vue';
    export default {
        mixins: [companies, product],
        props: ['comp_data'],
        data() {
            return {
                show_subscription_modal: false,
                show_withdraw_modal: false,
                show_request_access_modal: false,
                product_name: '',
                userType: 'Super Admin',
                show_subscribed_products: true,
                show_other_products: true,
                search_key: '',
                ajax_call_in_progress: false,
                products_data: {},
                product_id: '',
                subscription_text: '',
                error_text: '',
                comment: '',
                skip: 0,
                limit: 10,
                unsubscribed_count_scroll: 0,
                subscribed_count_scroll: 0,
                loading_scroll_subscribed: false,
                loading_scroll_unsubscribed: false,
                bottom: false
            };
        },
        components: {
            SubscriptionModal,
            HalfCircleSpinner,
            WithdrawSubscription,
            RequestAccess
        },
        methods: {
            openSubscriptionModal(prod_id, name) {
                this.product_name = name;
                this.product_id = prod_id;
                this.subscription_text = 'Subscription';
                this.show_subscription_modal = true;
                setTimeout(() => {
                    this.$modal.show('Subscription-Modal')
                }, 500)
            },
            requestAccess(id, name) {
                this.product_id = id;
                this.product_name = name;
                this.show_request_access_modal = true;
                setTimeout(() => {
                    this.$modal.show('Request-Access-Modal');
                }, 500);
            },
            hideRequestAccess() {
                this.show_request_access_modal = false;
                setTimeout(() => {
                    this.$modal.hide('Request-Access-Modal');
                }, 500);
                this.error_text = ''
            },
            async sendRequestAccess(params) {
                await this.$http.post(globals.AUTH_SERVICE + "/employees/product/request", params).then((response) => {
                    if(response.data.status_id == 1) {
                        this.show_request_access_modal = false;
                        setTimeout(() => {
                            this.$modal.hide('Request-Access-Modal')
                        }, 500);
                        this.getProductsList(this.loggedInUser.company_id);
                    }
                }).catch((err) => {
                    this.error_text = err.response.data.reason;
                });
            },
            async withdrawRequest(id) {
                try {
                    let params = {
                        product_id: id,
                        company_id: this.loggedInUser.company_id
                    }
                    let response = await this.withdrawProductAccess(params);
                    if(response.status_id == 1) {
                        this.show_withdraw_modal = true;
                        setTimeout(() => {
                            this.$modal.show('Withdraw-Modal');
                        }, 500)
                        this.getProductsList(this.loggedInUser.company_id);
                    }
                } catch(err) {
                    console.log(err);
                }
            },
            async withdrawSubscription(id) {
                try {
                    let params = {
                        product_id: id,
                        company_id: this.$route.params.company_id,
                    };
                    let response = await this.withdrawSubscriptionById(params);
                    if (response.status_id == 1) {
                        this.show_withdraw_modal = true;
                        setTimeout(() => {
                            this.$modal.show('Withdraw-Modal');
                        }, 500)
                    }
                }
                catch (error) {
                    console.log(error);
                }
            },
            hideSubscriptionModal() {
                this.show_subscription_modal = false
                this.error_text = ''
            },
            hideWithdrawModal() {
                this.show_withdraw_modal = false;
                setTimeout(() => {
                    this.$modal.hide('Withdraw-Modal')
                }, 500)
                if(this.loggedInUser.user_role == 'admin' || this.loggedInUser.user_role == 'employee') {
                    this.getProductsList(this.loggedInUser.company_id);
                } else if(this.$route.params.company_id) {
                    this.getProductsList(this.$route.params.company_id);
                }
                this.error_text = ''
            },
            goToProductPage(id) {
                if(this.loggedInUser.user_role == 'admin' || this.loggedInUser.user_role == 'employee') {
                    this.$router.push({
                        path: `/product/details/cmpid/${this.loggedInUser.company_id}/${id}`
                    })
                } else if(this.$route.params.company_id) {
                    this.$router.push({
                        path: `/product/details/cmpid/${this.$route.params.company_id}/${id}`
                    })
                }
            },
            toggleSubscribedProducts(){
                this.show_subscribed_products = !this.show_subscribed_products
            },
            toggleOtherProducts(){
                this.show_other_products = !this.show_other_products
            },
            showIndustryInformation() {
                if (this.industryTab) {
                    this.industryTab = false;
                } else {
                    this.industryTab = true;
                }
            },
            sendSubscription(data) {
                this.subscribeProd(data);
            },
            async subscribeProd(params) {
                    if(this.subscription_text == 'Subscription') {
                        await this.$http.post(globals.AUTH_SERVICE + `/company/products/add`, params).then(response => {
                            if(response.data.status_id == 1) {
                                this.show_subscription_modal = false;
                                setTimeout(() => {
                                    this.$modal.hide('Subscription-Modal')
                                }, 500);
                                this.getProductsList(this.$route.params.company_id);
                                EventBus.$emit('update_product_list_api');
                            }
                        }).catch((err)=>{
                            this.error_text = err.response.data.reason;
                        });
                    } else if (this.subscription_text == 'UnSubscription') {
                        delete params.comment;
                        await this.$http.post(globals.AUTH_SERVICE + `/company/products/remove`, params).then(response => {
                            if(response.data.status_id == 1) {
                                this.show_subscription_modal = false;
                                setTimeout(() => {
                                    this.$modal.hide('Subscription-Modal')
                                }, 500);
                                this.getProductsList(this.$route.params.company_id);
                                EventBus.$emit('update_product_list_api');
                            }
                        }).catch((err)=>{
                            this.error_text = err.response.data.reason;
                        });
                    }
            },
            async unSubscribeProd(prod_id, name) {
                this.product_name = name
                this.product_id = prod_id;
                this.subscription_text = 'UnSubscription';
                this.show_subscription_modal = true;
                setTimeout(() => {
                    this.$modal.show('Subscription-Modal')
                }, 500)
            },
            async getProductsList(comp_id) {
                this.ajax_call_in_progress = true;
                try {
                    let params = {
                        company_id: comp_id,
                        search_key: this.search_key,
                        skip: this.skip,
                        limit: this.limit
                    };
                    let response = await this.getAllProducts(params);
                    if(response.status_id == 1) {
                        this.products_data = response;
                        this.unsubscribed_count_scroll = this.products_data.unsubscribed_products.length;
                        this.subscribed_count_scroll = this.products_data.subscribed_products.length;
                    }
                    this.ajax_call_in_progress = false;
                }
                catch(err) {
                    this.ajax_call_in_progress = false;
                }
            },
            getProducts() {
                if(this.loggedInUser.user_role == 'admin' || this.loggedInUser.user_role == 'employee') {
                    this.getProductsList(this.loggedInUser.company_id);
                } else if(this.$route.params.company_id) {
                    this.getProductsList(this.$route.params.company_id);
                }
            },
            async infiniteScrollSubscribed(e) {
                const {
                    target
                } = e;
                if (Math.ceil(target.scrollTop) >= target.scrollHeight - target.offsetHeight) {
                    //this code will run when the user scrolls to the bottom of this div so 
                    //you could do an api call here to implement lazy loading
                    try {
                        this.loading_scroll_subscribed = true
                        let params = {
                            company_id: this.$route.params.company_id,
                            search_key: this.search_key,
                            skip: this.subscribed_count_scroll,
                            limit: this.limit
                        };
                        let res = await this.getAllProducts(params);
                        if (res.skip == this.subscribed_count_scroll) {
                            let tempFiles = res.subscribed_products
                            tempFiles.forEach(el => {
                                this.products_data.subscribed_products.push(el)
                            })
                            this.products_data.subscribed_products = this.products_data.subscribed_products.filter((thing, index) => {
                                const payload = JSON.stringify(thing);
                                return index === this.products_data.subscribed_products.findIndex(obj => {
                                    return JSON.stringify(obj) === payload;
                                });
                            });
                            this.subscribed_count_scroll += res.subscribed_products.length
                            this.loading_scroll_subscribed = false
                        }
                        if (res.subscribed_products.length < 10) {
                            document.getElementById('spinner').style.display = 'none'
                            this.loading_scroll_subscribed = false
                        }
                    } catch (reason) {

                    }
                    this.bottom = true;

                }
            },
            async infiniteScrollUnSubscribed(e) {
                const {
                    target
                } = e;
                if (Math.ceil(target.scrollTop) >= target.scrollHeight - target.offsetHeight) {
                    //this code will run when the user scrolls to the bottom of this div so 
                    //you could do an api call here to implement lazy loading
                    try {
                        this.loading_scroll_unsubscribed = true
                        let params = {
                            company_id: this.$route.params.company_id,
                            search_key: this.search_key,
                            skip: this.unsubscribed_count_scroll,
                            limit: this.limit
                        };
                        let res = await this.getAllProducts(params);
                        if (res.skip == this.unsubscribed_count_scroll) {
                            let tempFiles = res.unsubscribed_products
                            tempFiles.forEach(el => {
                                this.products_data.unsubscribed_products.push(el)
                            })
                            this.products_data.unsubscribed_products = this.products_data.unsubscribed_products.filter((thing, index) => {
                                const payload = JSON.stringify(thing);
                                return index === this.products_data.unsubscribed_products.findIndex(obj => {
                                    return JSON.stringify(obj) === payload;
                                });
                            });
                            this.unsubscribed_count_scroll += res.unsubscribed_products.length
                            this.loading_scroll_unsubscribed = false
                        }
                        if (res.unsubscribed_products.length < 10) {
                            document.getElementById('spinner1').style.display = 'none'
                            this.loading_scroll_unsubscribed = false
                        }
                    } catch (reason) {

                    }
                    this.bottom = true;

                }
            }
        },
        mounted() {
            this.getProducts();
            EventBus.$on('searchProds', (search_key) => {
                this.search_key = search_key;
                this.getProducts();
            })
        },
        computed: {
			loggedInUser() {
				return this.$store.state.loggedInUser;
			},
		},
    };
</script>

<style scoped>
    .product-access-container {
        background-color: #fff;
        padding: 15.5px 0 0px 0;
        border-radius: 6px;
    }

    .create-company-header {
        padding: 9.9px 15px;
        background-color: #00448b;
    }

    .w-150pxcenter {
        max-width: 150px;
        margin: 0 auto;
    }

    .subs-btn {
        background-color: #128807;
        color: #fff;
        border-radius: 5px;
    }

    .access-tab{
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.41);
    }

    .access-tabs {
        border-radius: 5px !important;
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.41) !important;
    }

    .arrow_up{
        transform: rotate(180deg);
    }
    .arrow_down{
        transform: rotate(0deg);
    }
</style>